<div class="modal-header">
    <b class="modal-title pull-left"
        >Thank you for filling out your information!</b
    >
    <button
        type="button"
        class="btn-close close pull-right"
        aria-label="Close"
        (click)="onClick()"
    >
        <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
</div>
<div class="loading-template">
    <div class="section">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    Thank you for reaching out to us.<br />
                    We value your time and appreciate the opportunity to assist
                    you.

                    <br /><br />
                    For urgent concerns/inquiries, you may contact us directly
                    through:<br />
                    Chong Hua Hospital (Cebu) : +63 (32) 255-8000<br />
                    Chong Hua Hospital Mandaue : +63 (32) 233-8000<br />

                    <br /><br />

                    Or you may send us a message through our Facebook Messenger
                    at
                    <a
                        href="https://www.facebook.com/ChongHuaHospital"
                        target="_BLANK"
                        >Chong Hua Hospital</a
                    >
                    where our virtual assistants will be glad to help you from
                    Monday to Saturday, 7:00AM to 9:00PM.

                    <br /><br />

                    Otherwise, we will reply by email within 48 hours.

                    <br /><br />

                    Sincerely,<br />
                    Chong Hua Hospital
                </div>
                <div class="col-12"><br /></div>
            </div>
        </div>
    </div>
</div>
