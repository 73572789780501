<footer class="pb-70 pt-100">
    <!--div class="newsletter-area">
        <div class="container">
            <div class="row newsletter-wrap align-items-center">
                <div class="col-lg-6">
                    <div class="newsletter-item">
                        <h2>Join Our Newsletter</h2>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod.
                        </p>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="newsletter-item">
                        <div class="newsletter-form">
                            <form class="newsletter-form">
                                <input
                                    type="email"
                                    class="form-control"
                                    placeholder="Enter Your Email"
                                    name="EMAIL"
                                />
                                <button
                                    class="btn newsletter-btn"
                                    type="submit"
                                >
                                    Subscribe
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div-->
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="footer-item">
                    <div class="footer-contact">
                        <h2>
                            <span
                                style="color: white; text-decoration: underline"
                                >Contact Us :</span
                            ><br />
                        </h2>
                        <h3><span style="font-weight: normal">Cebu</span></h3>

                        <ul>
                            <li>
                                <i class="icofont-ui-message"></i>
                                <a href="mailto:info@chonghua.com.ph"
                                    >info_cebu@chonghua.com.ph</a
                                >
                            </li>
                            <li>
                                <i class="icofont-phone"></i>
                                <a href="tel:+63 (32) 255-8000"
                                    >Call: +63 (32) 255-8000</a
                                >
                            </li>
                            <li>
                                <i class="icofont-location-pin"></i>
                                <a href="#"
                                    >Don Mariano Cui Street, Corner J. Llorente
                                    Street, Brgy. Capitol Site, Cebu City, Cebu,
                                    Philippines 6000</a
                                >
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="footer-item">
                    <div class="footer-contact">
                        <h2><span></span><br /></h2>
                        <h3>
                            <span style="font-weight: normal">Mandaue</span>
                        </h3>
                        <ul>
                            <li>
                                <i class="icofont-ui-message"></i>

                                <a href="mailto:info@chonghua.com.ph"
                                    >info_mandaue@chonghua.com.ph</a
                                >
                            </li>
                            <li>
                                <i class="icofont-phone"></i>

                                <a href="tel:+63 (32) 233-8000"
                                    >Call: +63 (32) 233-8000</a
                                >
                            </li>
                            <li>
                                <i class="icofont-location-pin"></i>

                                <a href="#">
                                    Mantawi Int'l Drive, City South Economic
                                    Administrative Zone, Mandaue City, Cebu,
                                    Philippines 6014</a
                                >
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <!--div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-quick">
                        <h3>Quick Links</h3>
                        <ul>
                            <li><a routerLink="/about">About us</a></li>
                            <li><a routerLink="/doctor">Our Doctors</a></li>
                            <li>
                                <a
                                    routerLink="https://pxi.chonghua.com.ph/"
                                    target="_BLANK"
                                    >Patient Experience</a
                                >
                            </li>
                            <li>
                                <a routerLink="/promos-packages"
                                    >Promos & Packages</a
                                >
                            </li>
                            <li><a routerLink="/careers">Career Center</a></li>
                            <li><a routerLink="/contact-us">Contact us</a></li>
                        </ul>
                    </div>
                </div>
            </div-->

            <div class="col-sm-6 col-lg-4">
                <div class="footer-item">
                    <div class="footer-feedback">
                        <h2>
                            <span
                                style="color: white; text-decoration: underline"
                                >Feedback</span
                            ><br />
                        </h2>
                        <span style="color: Red" *ngIf="siteError"
                            >*Site is Required<br
                        /></span>
                        <span style="color: Red" *ngIf="nameError"
                            >*Name is Required<br
                        /></span>
                        <span style="color: Red" *ngIf="emailError"
                            >*Email is Required<br
                        /></span>
                        <span style="color: Red" *ngIf="phoneError"
                            >*Phone Number is Required<br
                        /></span>
                        <span style="color: Red" *ngIf="subjectError"
                            >*Subject is Required<br
                        /></span>
                        <span style="color: Red" *ngIf="messageError"
                            >*Message is Required<br
                        /></span>
                        <span
                            style="color: Red"
                            *ngIf="error != ''"
                            [innerHTML]="error"
                        ></span>
                        <form>
                            <h3
                                class="form-title"
                                style="color: white; margin-bottom: 0"
                            >
                                SITE
                            </h3>
                            <div
                                class="radio-buttons"
                                style="padding-bottom: 10px"
                            >
                                <label
                                    class="custom-label"
                                    style="
                                        display: inline-block;
                                        margin-right: 20px;
                                    "
                                >
                                    <input
                                        type="radio"
                                        name="site"
                                        [(ngModel)]="selectedSite"
                                        value="Cebu"
                                    />
                                    &nbsp;&nbsp;Cebu
                                </label>
                                <label
                                    class="custom-label"
                                    style="display: inline-block"
                                >
                                    <input
                                        type="radio"
                                        name="site"
                                        [(ngModel)]="selectedSite"
                                        value="Mandaue"
                                    />
                                    &nbsp;&nbsp;Mandaue
                                </label>
                            </div>

                            <div class="form-group">
                                <input
                                    type="text"
                                    name="name"
                                    class="form-control"
                                    placeholder="Name"
                                    [(ngModel)]="name"
                                />
                            </div>
                            <div class="form-group">
                                <input
                                    type="email"
                                    name="email"
                                    class="form-control"
                                    placeholder="Email"
                                    [(ngModel)]="eemail"
                                />
                            </div>
                            <div class="form-group">
                                <input
                                    type="text"
                                    name="phone_number"
                                    class="form-control"
                                    placeholder="Phone"
                                    [(ngModel)]="phone"
                                />
                            </div>
                            <div class="form-group">
                                <input
                                    type="text"
                                    name="msg_subject"
                                    class="form-control"
                                    placeholder="Subject"
                                    [(ngModel)]="subject"
                                />
                            </div>
                            <div class="form-group">
                                <textarea
                                    name="message"
                                    class="form-control"
                                    cols="30"
                                    rows="5"
                                    placeholder="Your Message"
                                    [(ngModel)]="message"
                                ></textarea>
                            </div>
                            <ngx-recaptcha2
                                siteKey="6LcZ5HcjAAAAAMGlbzzV8s9UYfujBytGMFybHwTI"
                                (success)="handleSuccess($event)"
                                useGlobalDomain="false"
                            >
                            </ngx-recaptcha2>
                            <!--form [formGroup]="aFormGroup">
                                <ngx-invisible-recaptcha
                                    #captchaElem
                                    [siteKey]="siteKey"
                                    (success)="handleSuccess($event)"
                                    [ngModel]="recaptcha"
                                    [ngModelOptions]="{ standalone: true }"
                                >
                                </ngx-invisible-recaptcha>
                            </form
                             [disabled]="!isSuccess"
                            -->
                            <div class="text-left">
                                <button
                                    type="submit"
                                    class="btn feedback-btn"
                                    (click)="send()"
                                >
                                    Submit
                                </button>
                                <!--button
                                    type="submit"
                                    class="drop-btn"
                                    (click)="send()"
                                >
                                    Send
                                </button-->
                                <!--span class="cRed" *ngIf="!isEmail"
                                    >&nbsp;&nbsp;&nbsp;*Invalid Email</span
                            --></div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-lg-12">
                <div class="footer-item">
                    <div class="footer-feedback">
                        <h3>Quick Links</h3>
                        <div class="container">
                            <div class="row">
                                <!--div class="col-lg-2 col-6 custom">
                                    <div class="custom">
                                        <span routerLink="/about">Home</span>
                                    </div>
                                </div-->
                                <div class="col-lg-2 col-6 custom">
                                    <div class="custom">
                                        <span routerLink="/about"
                                            >About Us</span
                                        >
                                    </div>
                                </div>
                                <div class="col-lg-2 col-6 custom">
                                    <div class="custom">
                                        <span routerLink="/doctors"
                                            >Our Doctors</span
                                        >
                                    </div>
                                </div>
                                <div class="col-lg-2 col-6 custom">
                                    <div class="custom">
                                        <span
                                            routerLink="/continuing-medical-education"
                                            >Continuing Medical Education</span
                                        >
                                    </div>
                                </div>

                                <div class="col-lg-2 col-6 custom">
                                    <div class="custom">
                                        <span routerLink="/admission-procedure"
                                            >Patients & Visitors Guide</span
                                        >
                                    </div>
                                </div>
                                <div class="col-lg-2 col-6 custom">
                                    <div class="custom">
                                        <a
                                            href="https://pxi.chonghua.com.ph"
                                            target="_BLANK"
                                            style="color: white !important"
                                            >Patient Portal</a
                                        >
                                    </div>
                                </div>
                                <div class="col-lg-2 col-6 custom">
                                    <div class="custom">
                                        <span routerLink="/promos-packages"
                                            >What's New</span
                                        >
                                    </div>
                                </div>
                                <div class="col-lg-2 col-6 custom">
                                    <div class="custom">
                                        <span routerLink="/careers"
                                            >Career Center</span
                                        >
                                    </div>
                                </div>
                                <div class="col-lg-2 col-6 custom">
                                    <div class="custom">
                                        <span routerLink="/irb/home"
                                            >Institutional Review Board</span
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>

<!--div class="copyright-area">
    <div class="container">
        <div class="copyright-item">
            <p>Chong Hua Hospital</p>
        </div>
    </div>
</div-->
