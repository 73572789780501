<app-preloader></app-preloader>
<!--
<ng-container *ngIf="location.includes('/irb')">
    <irb2-navbar></irb2-navbar>
    <router-outlet></router-outlet>
    <irb2-footer></irb2-footer
></ng-container>

<ng-container *ngIf="!location.includes('/irb')">
    <app-navbar *ngIf="!(location == '/coming-soon')"></app-navbar>
    <router-outlet></router-outlet>
    <app-footer *ngIf="!(location == '/coming-soon')"></app-footer>
</ng-container>
-->
<ng-container>
    <app-navbar *ngIf="!(location == '/coming-soon')"></app-navbar>
    <router-outlet></router-outlet>
    <app-footer *ngIf="!(location == '/coming-soon')"></app-footer>
</ng-container>
