import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: "root",
})
export class GeneralService {
    constructor(private http: HttpClient) {}
    defaultGet(endPoint) {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
        });
        const options = { headers: headers };
        const url =
            environment.apiRouterUrl +
            "api/" +
            endPoint +
            "?filters[approver_id1][$eq]=1&filters[approver_id1_status][$eq]=true&sort=createdAt:DESC";
        return this.http.get(url, options);
    }
    adminGet(endPoint) {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
        });
        const options = { headers: headers };
        const url =
            environment.apiRouterUrl +
            "api/" +
            endPoint +
            "?sort=createdAt:DESC";
        return this.http.get(url, options);
    }
    get(endPoint) {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
        });
        const options = { headers: headers };
        const url = environment.apiRouterUrl + "api/" + endPoint;
        return this.http.get(url, options);
    }
    add(endPoint, data) {
        const headers = new HttpHeaders({ "Content-Type": "application/json" });
        const options = { headers: headers, withCredentials: true };
        const url = environment.apiRouterUrl + "api/" + endPoint;
        return this.http.post(url, data, options);
    }
    strapiPut(endPoint, data) {
        const headers = new HttpHeaders({ "Content-Type": "application/json" });
        const options = { headers: headers, withCredentials: true };
        const url = environment.apiRouterUrl + "api/" + endPoint;
        return this.http.put(url, data, options);
    }
    getCustomQuery(endPoint) {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
        });
        const options = { headers: headers };
        const url = environment.apiRouterUrl + endPoint;
        return this.http.get(url, options);
    }
    addPost(urld, data) {
        //console.log(data);

        const headers = new HttpHeaders({ "Content-Type": "application/json" });
        const options = { headers: headers, withCredentials: true };
        const url = environment.apiRouterUrl + urld;
        return this.http.post(url, data, options);
    }
    deletePost(urld) {
        const headers = new HttpHeaders({ "Content-Type": "application/json" });
        const options = { headers: headers, withCredentials: true };
        const url = environment.apiRouterUrl + urld;
        return this.http.delete(url, options);
    }
    put(endPoint, data, id) {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
        });
        const options = { headers: headers, withCredentials: false };
        //const url = environment.apiRouterUrl + "v2/ProfFee";
        const url = environment.apiRouterUrl + "api/" + endPoint + "/" + id;
        //console.log(url);

        return this.http.put(url, data, options);
    }

    delete(collection, id) {
        const headers = new HttpHeaders({ "Content-Type": "application/json" });
        const options = { headers: headers, withCredentials: true };
        const url = environment.apiRouterUrl + "api/" + collection + "/" + id;
        return this.http.delete(url, options);
    }
    deleteCustom(collection) {
        const headers = new HttpHeaders({ "Content-Type": "application/json" });
        const options = { headers: headers, withCredentials: true };
        const url = environment.apiRouterUrl + "api/" + collection;
        return this.http.delete(url, options);
    }
    getDoctorsDirectory() {
        const headers = new HttpHeaders({
            "Content-Type": "application/json;charset=utf-8",
        });
        const options = { headers: headers, withCredentials: true };
        const url = environment.API_URL + "gw/pub/doctors";
        console.log(url);

        //const url =
        //    environment.API_URL + "gw/gw/pub/doctorclinicdetailsall?mode=T";

        return this.http.get(url, options);
    }
    getClinic(drCode) {
        const headers = new HttpHeaders({
            "Content-Type": "application/json;charset=utf-8",
        });
        const options = { headers: headers, withCredentials: true };
        const url =
            environment.API_URL +
            "gw/pub/doctorclinicdetails?drCode=" +
            drCode +
            "&mode=P";
        return this.http.get(url, options);
    }
    sendEmail(data) {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
        });
        const options = { headers: headers, withCredentials: true };
        const url = environment.API_URL + "gw/pxi/notification/send-email";
        //const url = "https://localhost:44333/api/pxi/notification/send-email";
        return this.http.post(url, data, options);
    }
    getapi(drCode) {
        const headers = new HttpHeaders({
            "Content-Type": "application/json;charset=utf-8",
        });
        const options = { headers: headers, withCredentials: true };
        const url = environment.API_URL + drCode;
        return this.http.get(url, options);
    }
    postResume(data: FormData) {
        const headers = new HttpHeaders({
            // Do not set 'Content-Type' here, the browser will handle it
        });
        const options = { headers: headers, withCredentials: true };
        const url = environment.API_URL1 + "gw/pxiwildcard1/User/PostResume";
        //const url = "http://localhost:45628/gw/pxiwildcard1/user/postresume";
        return this.http.post(url, data, options);
    }
}
